
import Swiper from 'swiper/bundle';

import 'swiper/css/bundle';




// equal height column
let currentElement = document.querySelector('.dynamic-column');
if (currentElement) {
    let nextElement = currentElement.nextElementSibling;
    if (nextElement) {
        nextElement.style.height = currentElement.offsetHeight + 'px';
        nextElement.style.width = window.innerWidth - currentElement.offsetWidth + 'px';
    }
}


// audio and swiper
document.addEventListener('DOMContentLoaded', function() {
    document.querySelectorAll(".o-series-slider").forEach(function(container) {
        var swiperElement = container.querySelector(".swiper");
        var nextButton = container.querySelector(".swiper-button-next");
        var prevButton = container.querySelector(".swiper-button-prev");
    
        if (swiperElement && nextButton && prevButton) {
            var swiper = new Swiper(swiperElement, {
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton
                }
            });
        }
    });

    const audioIcons = document.querySelectorAll('.icon-audio');

    if (audioIcons.length > 0) {
        audioIcons.forEach(audioIcon => {
            let audioSrc = audioIcon.getAttribute('data-audio');
            let audio = new Audio(audioSrc);
            let isPlaying = false;

            audioIcon.addEventListener('click', function() {
                if (!isPlaying) {
                    audio.play();
                    audioIcon.classList.add('active');
                    isPlaying = true;
                } else {
                    audio.pause();
                    audioIcon.classList.remove('active');
                    isPlaying = false;
                }

                audio.addEventListener('ended', function() {
                    audioIcon.classList.remove('active');
                    isPlaying = false;
                });
            });
        });
    }
});

// dark theme 
document.addEventListener('DOMContentLoaded', function() {
    const darkThemeButtons = document.querySelectorAll('.dark-theme-js');
    darkThemeButtons.forEach(function(button) {
      button.addEventListener('click', toggleDarkTheme);
    });
});

function toggleDarkTheme() {
    const html = document.documentElement;
    const currentTheme = html.getAttribute('data-bs-theme');
    const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
    html.setAttribute('data-bs-theme', newTheme);

    const secondButton = document.querySelectorAll('.dark-theme-js')[1];
    if (newTheme === 'dark') {
        secondButton.innerHTML = '<i class="icon-moon text-p2 text-gray text-p2 me-8"></i> Switch to light mode';
    } else {
        secondButton.innerHTML = '<i class="icon-moon text-p2 text-gray text-p2 me-8"></i> Switch to dark mode';
    }
}


// Search
var search = new Choices(
    document.getElementById('js-choice'),
    {
        allowHTML: true,
        delimiter: ',',
        editItems: true,
        placeholderValue: 'click to search',
        removeItemButton: true,
    }
);